<div class="wncModal">
    <div class="panelHeader">
      <div class="row">
        <div class="col-md-6 panelTitle">
          <div class="col-md-12 no-pad">
            <h3 class="white-text header-padding-left">Rent Roll</h3>
          </div>
        </div>      
        <div class="col-md-6 panelButton">
          <!--<button type="button"  class="btn btn-outline-danger red-background"  (click)="removeAllUnitFiles()">Remove All Files</button>-->
          <button type="button"  class="btn btn-outline-primary white-background"  (click)="openUploadFileModal()"><i class="fa fa-plus" aria-hidden="true"></i> &#160;Upload File</button>
          <button type="button" class="btn btn-outline-danger white-background" (click)="closeModal()"><i class="fa fa-times" aria-hidden="true"></i> &#160;Close</button>
        </div>
      </div>
    </div>  
    <div class="panelBody">
      <div style="padding-top: 10px;">
        <nav class="navbar navbar-expand-lg navbar-light bg-light border-top border-right border-left" style="padding-right: 0px;">
          <div class="col-md-12" style="padding-right: 0px;">
            <span class="navbar-brand mb-0 h6">Rent Roll Files</span>
          </div>
        
          <ng-content></ng-content>
        </nav>
      <ag-grid-angular class="ag-theme-balham"
                       style="height:500px"
                       [pagination]="true"
                       [gridOptions]="gridOptions"
                       [columnDefs]="rentRollListColumns"
                       [defaultColDef]="defaultColDef"
                       [sideBar]="false"
                       [context]="context"
                       [components]="frameworkComponents"
                       (columnVisible)="onColumnVisible($event)"
                       (gridReady)="onGridReady($event)" 
                       [rowData]="rentRollList"
                       [getRowId]="getRowId">
      </ag-grid-angular>

    </div>
  
    </div>
  </div>